.section {
  margin: 0 0 2rem;
  padding: 0 0 1rem;
  width: 100%;
}

.section-header {
  background-color: var(--grey);
  color: #fff;
  text-align: center;
  padding: 2rem 0;
}

.section-header h2, .section-header h3 {
  margin: 0;
}

.section-header h3 {
  font-size: 2.5rem;
  color: var(--dark-blue);
}
