.pba-raw-page {
  text-align: center;
  margin: 4rem auto;
}

.pba-raw-page h2,
.pba-raw-page h3 {
  color: var(--dark-blue);
  font-weight: 800;
  text-transform: uppercase;
  font-size: 3.5rem;
}

.pba-raw-page .logo {
  text-align: center;
}

.pba-raw-page .logo img {
  width: 80%;
}

.pba-raw-page p {
  font-size: 2.2rem;
  font-weight: 600;
  text-align: left;
}

.pba-raw-page span {
  font-size: 1.8rem;
  font-weight: 800;
  color: var(--dark-blue);
}

@media (min-width: 40rem) {
  .pba-raw-page {
    text-align: left;
  }

  .raw-page-header {
    position: relative;
    margin-top: 4rem;
  }

  .raw-page-header .logo {
    position: absolute;
    top: -2rem;
    left: 0;
    z-index: 1;
  }

  .raw-page-content {
    margin: -2rem 0;
  }
}

@media (min-width: 60rem) {

  .raw-page-header {
    margin-top: 0;
  }

  .raw-page-content {
    margin: 0;
  }
}
