.carousel {
  position: relative;
}

.carousel-light {
  background-color: var(--white);
}

.carousel-dark {
  background-color: var(--grey);
}

.carousel-dark .carousel-nav {
  color: var(--white);
}

.carousel-nav {
  position: absolute;
  cursor: pointer;
  z-index: 1;
  display: table-cell;
  font-size: 4rem;
  color: var(--grey);
}

.carousel-nav-left {
  left: 0;
  top: 50%;
}

.carousel-nav-right {
  right: 0;
  top: 50%;
}

.carousel-cells {
  overflow-x: hidden;
  position: relative;
  padding: 2rem;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.carousel-cell {
  max-height: 450px;
  padding: 0 2.5rem;
}

.carousel-cell .carousel-image {
  display: block;
  height: 250px;
  margin: 1rem 0;
}

.carousel-cell .carousel-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.carousel-caption {
  text-align: center;
  padding: 0 2rem;
}

.carousel-dark .carousel-caption p,
.carousel-dark .carousel-caption h5 {
  width: 100%;
  color: #fff;
  margin: 0;
}

.carousel-caption h5 {
  font-size: 1.5rem;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0;
}

