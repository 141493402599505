:root {
  --grey: #80929F;
  --dark-blue: #3F5073;
  --blue: #818CA3;
  --grey-blue: #3F5073;
  --white: #fff;
  --black: #000;
}

body {
  margin: 0;
  font-family: montserrat, sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

h1, h2 {
  text-transform: uppercase;
  font-size: 4rem;
  font-weight: 800;
}

h3 {
  font-weight: 600;
}

p {
  font-weight: 600;
  font-size: 1.2rem;
}

a, a:visited, a:hover, a:active {
  text-decoration: none;
  color: var(--dark-blue);
}
