.pba-case-study {
  margin: 4rem auto;
}
.case-study .row {
  margin-bottom: 2rem;;
}

.case-study h2,
.case-study h3 {
  color: var(--dark-blue);
  font-weight: 800;
  text-transform: uppercase;
  margin-bottom: 0;
}

.case-study h2 {
  font-size: 2.5rem;
}
.case-study h3 {
  font-size: 3.5rem;
}

.case-study p {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 0;
}

.case-study span {
  font-size: 1.8rem;
  font-weight: 800;
  color: var(--dark-blue);
}
