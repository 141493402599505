div.section:last-of-type {
  margin-bottom: 0;
  padding-bottom: 0;
}
.footer {
  text-align: center;
  width: 100%;
}

.footer a, .footer a:visited {
  color: #fff;
}

.footer-copy {
  width: 81%;
  margin: 0 auto;
  padding: 2rem;
  font-weight: 600;
}

.footer-logo {
  padding: 2rem;
  /* margin-bottom: 2rem; */
}

.footer-contact-bar {
  background-color: var(--grey);
  padding: 0 0 2rem;
}

.footer-contact {
  font-size: 1.9rem;
  font-weight: 800;
  margin: 2rem 0 0;
  background-color: var(--grey);
}

.footer-socials {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2rem 5rem;
  margin-bottom: 0;
}

.footer-socials a, .footer-socials a:visited {
  color: #fff;
  text-decoration: none;
}

.footer-socials li a {
  height: 3.5rem;
  width: 3.5rem;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  padding-top: 0.7rem;
  background-color: var(--dark-blue);
  text-align: center;
  margin: 0 0.5rem;
}

@media (min-width: 40rem) {
  .left {
    text-align: left;
  }
  .right {
    text-align: right;
  }

}

