.header {
  background-repeat: no-repeat;
  display: block;
}

.header h1 {
  color: var(--grey-blue);
}

.header-greeting {
  display: none;
}

.header-intro {
  text-align: center;
}

.header p {
  margin: 0;
  font-size: 1.7rem;
}

@media (min-width: 40rem) {
  .header {
    padding-right: 4rem;
    background-size: auto 20rem;
    background-position: top 0 right 0;
  }
  .header-greeting {
    text-align: right;
    background-size: contain;
    display: block;
    color: #fff;
    margin: 11rem 0 0;
  }

  .header-greeting h2 {
    margin: 1rem 0 0;
  }

  .header-greeting p {
    margin: 0;
  }

  .header-intro {
    text-align: right;
    margin: 3rem 0 0;
  }
}
