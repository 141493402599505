.pba-back {
  font-size: 2em;
  font-weight: 500;
}

.pba-back .small {
  font-size: .6em;
  font-weight: 800;
  text-transform: uppercase;
}
